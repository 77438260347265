import React, { useEffect, useState } from 'react'
import axios from 'axios';
import baseUrl from './Constant';
import { useLocation } from 'react-router-dom';
import '../Css/InvoiceBill.css'


function InvoiceBill() {
    const [invoiceData, setInvoiceData] = useState('')
    const [user, setUser] = useState('')
    const [invoiceDetail, setInvoiceDetail] = useState('')

    const location = useLocation();
    const { itemUid } = location.state || {};


    const getViewBill = async () => {
        const token = JSON.parse(localStorage.getItem('token'))
        try {
            const res = await axios.get(`${baseUrl}/adminpannel/invoice-payment-view/${itemUid}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
            setInvoiceDetail(res.data)
            setUser(res.data.user)
            setInvoiceData(res.data)
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        getViewBill()
    }, [itemUid])

    return (
        <div>
            <div className="container my-5 invoice-container">
                <div className="row mb-4">
                    <div className="col-8 headerbill d-flex align-items-center">
                        <img src='/Image/logo.png' height={130} width={130} />
                        <div>
                            <h4>GHOSTING WEBTECH PVT LTD</h4>
                            <h6>
                                1st Floor, S/460, Bashant vihar Market,<br /> near Panch Shiv Mandir, Kankarbagh Colony, <br />Patna, Bihar 800020
                            </h6>
                        </div>


                    </div>
                    <div className="col-4 header invoice-title">
                        <h1>INVOICE</h1>
                    </div>
                </div>

                <div className="row mb-4 invoice-details">
                    <div className="col-6">
                        <h5>Invoice To</h5>
                        <p>
                            <strong style={{ color: "#ffd54f", fontSize: "25px" }}> {user?.full_name || 'Client Details'}</strong><br />
                            Phone: {user?.phone_number || 'N/A'}<br />
                            Email Id:{user?.email || 'N/A'}<br />
                            {user?.addressddet || 'N/A'}
                        </p>
                    </div>
                    <div className="col-6 text-end">
                        <p>
                            <strong>Invoice No:</strong> {invoiceDetail?.invoice_no || 'N/A'}<br />
                            <strong>Date:</strong> {invoiceDetail?.ordered_date || 'N/A'}<br />
                            <strong>Amount Status:</strong> {invoiceDetail?.is_paid ? "Paid" : "Unpaid" || 'N/A'}

                        </p>

                    </div>

                </div>

                <table className="table table-striped">
                    <thead className="table-light">
                        <tr>
                            <th className='p-3'>Item Description</th>
                            <th className='p-3'>Price</th>
                            <th className='p-3'>Qty</th>
                            <th className='p-3'>Total</th>
                        </tr>
                    </thead>
                    <tbody>

                        {
                            invoiceData?.pursed_product?.map((product, idx) => {
                                console.log('Product:', product); // Log each product
                                return (
                                    <tr key={`${idx}`}>
                                        <td className='p-3'>{product.product.product_name}</td>
                                        <td className='p-3'>₹ {product.paid_amount}</td>
                                        <td className='p-3'>{product.quantity}</td>
                                        <td className='p-3'>₹ {(product.paid_amount * product.quantity).toFixed(2)}</td>
                                    </tr>
                                );
                            })
                        }


                    </tbody>
                </table>

                <div className="row">
                    <div className="col-9 payment-info">
                        <p><strong>Payment Info</strong></p>
                        <p>
                            A/c Holder Name:- GHOSTING WEBTECH PVT LTD<br />
                            Bank Name:- HDFC Bank<br />
                            Account no:- 50200086816993<br />
                            Account Type:- Current<br />
                            IFSC Code:-HDFC0006215<br />
                            Branch:- Kankarbagh Colony
                        </p>
                    </div>
                    <div className="col-3 text-end">
                        <table className="table total-table">
                            <tbody>
                                <tr>
                                    <td><strong>Sub Total</strong></td>
                                    <td>₹{invoiceData.amount} </td>
                                </tr>
                                <tr>
                                    <td><strong>Tax (18%)</strong></td>
                                    <td>₹{(invoiceData.amount * 0.18).toFixed(2)}</td>
                                </tr>
                                <tr className='table-dark'>
                                    <td><strong>Grand Total</strong></td>
                                    <td><strong>₹{(parseFloat(invoiceData.amount) + (invoiceData.amount * 0.18)).toFixed(2)}</strong></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

                <div className="row terms-conditions">
                    <div className="col-6">
                        <p><strong>TERMS & CONDITIONS</strong></p>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                    </div>
                    <div className="col-6 authorized-sign">
                        <p>____________________</p>
                        <p>Authorized Sign</p>
                    </div>
                </div>

                <div className="footer">
                    <p><strong>Thank you for business!</strong></p>
                </div>
            </div>

        </div>
    )
}

export default InvoiceBill;