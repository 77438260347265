import React, { useState, useEffect } from 'react';
import Header from './Header';
import Sidebar from './Sidebar';
import { useLocation } from 'react-router-dom';
import baseUrl from './Constant';
import axios from 'axios';
import {
    MDBContainer,
    MDBRow,
    MDBCol,
    MDBCard,
    MDBCardBody,
    MDBCardTitle,
    MDBCardText,
    MDBBtn,
    MDBModal,
    MDBModalDialog,
    MDBModalContent,
    MDBModalHeader,
    MDBModalTitle,
    MDBModalBody,
    MDBModalFooter,
} from 'mdb-react-ui-kit';
import { toast } from 'react-toastify';
import { TiDelete } from "react-icons/ti";
import InvoiceDetail from './InvoiceDetail';


function ClientDetail() {
    const location = useLocation();
    const { clientId } = location.state || {};

    const [sidebarOpen, setSidebarOpen] = useState(true);
    const [clientData, setClientData] = useState(null);
    const [modalOpen, setModalOpen] = useState(false);
    const [categories, setCategories] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState('');
    const [subCategories, setSubCategories] = useState([]);
    const [selectedSubCategory, setSelectedSubCategory] = useState('');
    const [product, setProduct] = useState([]);
    const [selectedProduct, setSelectedProduct] = useState('');
    const [price, setPrice] = useState('')
    const [purchasedProduct, setPurchasedProduct] = useState([])
    const [invoiceData, setInvoiceData] = useState([])

    const handleToggle = () => {
        setSidebarOpen(!sidebarOpen);
    };


    const getClientData = async () => {
        const token = JSON.parse(localStorage.getItem('token'));
        try {
            const res = await axios.get(`${baseUrl}/adminpannel/clientdetail/${clientId}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            setClientData(res.data);
        } catch (error) {
            console.log('Error fetching client data:', error);
        }
    };

    const getCategories = async () => {
        const token = JSON.parse(localStorage.getItem('token'));
        try {
            const res = await axios.get(`${baseUrl}/products/all-category/`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            setCategories(res.data.category);
        } catch (error) {
            console.log('Error fetching categories:', error);
        }
    };

    const getSubCategories = async (categorySlug) => {
        const token = JSON.parse(localStorage.getItem('token'));
        try {
            const res = await axios.get(`${baseUrl}products/all-sub-category/${categorySlug}`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            setSubCategories(res.data.subcategory);
        } catch (error) {
            console.log('Error fetching subcategories:', error);
        }
    };

    const handleCategoryChange = (e) => {
        const categorySlug = e.target.value;
        setSelectedCategory(categorySlug);
        getSubCategories(categorySlug);
    };

    const getProduct = async (subCategorySlug) => {
        const token = JSON.parse(localStorage.getItem('token'));
        try {
            const res = await axios.get(`${baseUrl}products/all-products/${subCategorySlug}`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            setProduct(res.data.product);

        } catch (error) {
            console.log('Error fetching products:', error);
        }
    };

    const handleSubCategoryChange = (e) => {
        const subCategorySlug = e.target.value;
        setSelectedSubCategory(subCategorySlug);
        getProduct(subCategorySlug);
    };

    const handleProductChange = (e) => {
        const productUid = e.target.value;
        setSelectedProduct(productUid);
    }



    const toggleModal = () => setModalOpen(!modalOpen);

    const handleProductSubmit = async () => {
        const token = JSON.parse(localStorage.getItem("token"))
        const data = {
            user: `${clientId}`,
            service_amount: price,
            product: selectedProduct // Replace with actual product value if needed
        };
        try {
            const res = await axios.post(`${baseUrl}/adminpannel/client-services/`, data, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
            console.log(res.data)
            toggleModal();
            toast.success("Product Added Sucessfully")
            getPurchasedProduct ()
        } catch (error) {
            console.log(error)
            toast.warning(error.message)
            toggleModal();
        }

    };

    const getPurchasedProduct = async () => {
        const token = JSON.parse(localStorage.getItem("token"))
        try {
            const res = await axios.get(`${baseUrl}/adminpannel/client-services/user/${clientId}`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
            console.log(res.data)
            setPurchasedProduct(res.data)
        } catch (error) {
            console.log(error)

        }

    }

    const invoiceList = async () => {
        const token = JSON.parse(localStorage.getItem("token"))
        try {
            const res = await axios.get(`${baseUrl}/adminpannel/invoice-payment-list/user/${clientId}`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })

            setInvoiceData(res.data)
        }
        catch (error) {
            console.log(error)
        }
    }

    const handleCreateInvoice = async () => {
        const token = JSON.parse(localStorage.getItem("token"))
        try {
            const res = await axios.get(`${baseUrl}/adminpannel/generate-client-payment/user/${clientId}`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
            if (res.status ==201){
                toast.success("Bill Generated Sucessfully")
                invoiceList()
            }
           
        }
        catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        if (clientId) {
            getClientData();
            getCategories();
        }
        getPurchasedProduct()
        invoiceList()
    }, [clientId]);


    return (
        <div>
            <Header handleToggle={handleToggle} />
            <div className="d-flex">
                <Sidebar isOpen={sidebarOpen} />
                <MDBContainer className="py-4">
                    <MDBRow>
                        <MDBCol md="5">
                            <MDBCard className="mb-4">
                                <MDBCardBody>
                                    <MDBCardTitle className="h3">
                                        {clientData?.client?.full_name || 'Client Details'}
                                    </MDBCardTitle>
                                    <MDBCardText>
                                        <strong>Email:</strong> {clientData?.client?.email || 'N/A'}
                                    </MDBCardText>
                                    <MDBCardText>
                                        <strong>Phone Number:</strong> {clientData?.client?.phone_number || 'N/A'}
                                    </MDBCardText>
                                    <MDBCardText>
                                        <strong>Address:</strong> {clientData?.client?.addressddet || 'N/A'}
                                    </MDBCardText>
                                    <MDBBtn className="btn btn-info" onClick={toggleModal}>
                                        Add Product
                                    </MDBBtn>
                                    <MDBBtn className="btn btn-info ms-5" onClick={toggleModal}>
                                        Add Address
                                    </MDBBtn>
                                </MDBCardBody>
                            </MDBCard>
                        </MDBCol>
                        <MDBCol md="7">
                            <MDBCard className="mb-4">
                                <MDBCardBody>
                                    <MDBCardTitle className="h4">Purchased Products</MDBCardTitle>
                                    {purchasedProduct?.length > 0 ? (
                                        <ul className='list-unstyled'>
                                            {purchasedProduct.map((product, index) => (

                                                <div key={index} className='d-flex gap-5 justify-content-between align-items-center border border-primary rounded mb-2 bg-info bg-opacity-10 py-2 px-3'>

                                                    <li key={index}>{product?.product} - ₹ {product.service_amount}</li>
                                                    <div >
                                                        <TiDelete size={30} color='red' />
                                                    </div>

                                                </div>
                                            ))}

                                        </ul>
                                    ) : (

                                        <MDBCardText>No products purchased.</MDBCardText>
                                    )}
                                </MDBCardBody>
                                {
                                    purchasedProduct ?.length > 0 ?(
                                        <button className='btn btn-info' onClick={handleCreateInvoice}>
                                            Create Invoice
                                        </button>
                                    ):null
                                }

                            </MDBCard>
                        </MDBCol>
                    </MDBRow>
                    <div className='p-4'>
                        <InvoiceDetail invoiceData={invoiceData} clientData={clientData} />
                    </div>
                </MDBContainer>
            </div>

            <MDBModal open={modalOpen} onClose={toggleModal} tabIndex="-1">
                <MDBModalDialog>
                    <MDBModalContent>
                        <MDBModalHeader>
                            <MDBModalTitle>Add Product</MDBModalTitle>
                            <MDBBtn className="btn-close" color="none" onClick={toggleModal}></MDBBtn>
                        </MDBModalHeader>
                        <MDBModalBody>
                            <form>
                                <div className="mb-3">
                                    <label htmlFor="productCategory" className="form-label">
                                        Category
                                    </label>
                                    <select
                                        className="form-select"
                                        id="Category"
                                        value={selectedCategory}
                                        onChange={handleCategoryChange}
                                    >
                                        <option value="">Select Category</option>
                                        {categories.map((category) => {

                                            return (
                                                <option key={category.uid} value={category.slug}>
                                                    {category.category_name}
                                                </option>
                                            );
                                        })}
                                    </select>
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="productName" className="form-label">
                                        SubCategory
                                    </label>
                                    <select
                                        className="form-select"
                                        id="productCategory"
                                        value={selectedSubCategory}
                                        onChange={handleSubCategoryChange}
                                    >
                                        <option value="">Select SubCategory</option>
                                        {subCategories.map((category) => (
                                            <option key={category.uid} value={category.slug}>
                                                {category.category_name}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="productName" className="form-label">
                                        Product
                                    </label>
                                    <select
                                        className="form-select"
                                        id="product"
                                        value={selectedProduct}
                                        onChange={handleProductChange}
                                    >
                                        <option value="">Select Product</option>
                                        {product.map((item) => (
                                            <option key={item.uid} value={item.uid}>
                                                {item.product_name} -  ₹{item.dis_price}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="productName" className="form-label">
                                        Price
                                    </label>
                                    <input className='form-control' value={price} type='text' onChange={(e) => {
                                        return (
                                            setPrice(e.target.value)
                                        )
                                    }} />
                                </div>
                            </form>
                        </MDBModalBody>
                        <MDBModalFooter>
                            <MDBBtn onClick={handleProductSubmit}>Save</MDBBtn>
                        </MDBModalFooter>
                    </MDBModalContent>
                </MDBModalDialog>
            </MDBModal>

        </div>
    );
}

export default ClientDetail;
