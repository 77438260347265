import React, { useState } from 'react';
import {
    MDBBtn,
    MDBModal,
    MDBModalDialog,
    MDBModalContent,
    MDBModalHeader,
    MDBModalTitle,
    MDBModalBody,
    MDBModalFooter,
    MDBInput,
} from 'mdb-react-ui-kit';
import axios from 'axios'
import baseUrl from './Constant';
import { toast } from 'react-toastify';

export default function AddClientModal({ basicModal, toggleOpen, onClientAdded}) {

    const [fullName, setFullName] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [email, setEmail] = useState('');

    const handleAddClient = async () => {

        const clientData = {
            full_name: fullName,
            phone_number: phoneNumber,
            email: email
        };
        console.log(clientData)
        const token = JSON.parse(localStorage.getItem('token'));

        try {
            const res = await axios.post(`${baseUrl}/adminpannel/add-user/`, clientData, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
            console.log(res.data)
            toast.success("Client added successfully");
            onClientAdded(res.data)
            toggleOpen()
        }
        catch (error) {
            console.log(error);
            if (error.response && error.response.data) {
                const errors = error.response.data;
                if (errors.phone_number) {
                    toast.warning(errors.phone_number[0]);
                }
                if (errors.email) {
                    toast.warning(errors.email[0]);
                }
            } else {
                toast.warning("An error occurred");
            }
            toggleOpen();
        }
    };



    return (
        <>
            <MDBModal open={basicModal} setShow={toggleOpen} tabIndex='-1'>
                <MDBModalDialog >
                    <MDBModalContent>
                        <MDBModalHeader>
                            <MDBModalTitle>Add Client Detail</MDBModalTitle>
                            <MDBBtn className='btn-close' color='none' onClick={toggleOpen}></MDBBtn>
                        </MDBModalHeader>
                        <MDBModalBody>
                            <MDBInput
                                label="Full Name"
                                id="name"
                                type="text"
                                className='mb-2 py-2'
                                onChange={(e) => setFullName(e.target.value)}
                            />

                            <MDBInput
                                label="Mobile Number"
                                id="phone"
                                type="tel"
                                className='mb-2 py-2'
                                onChange={(e) => setPhoneNumber(e.target.value)}
                            />


                            <MDBInput label="Email Id"
                                id="email"
                                type="email"
                                className='py-2'
                                onChange={(e) => setEmail(e.target.value)}
                            />

                        </MDBModalBody>

                        <MDBModalFooter>
                            <MDBBtn onClick={handleAddClient}>Save</MDBBtn>
                        </MDBModalFooter>
                    </MDBModalContent>
                </MDBModalDialog>
            </MDBModal>
        </>
    );
}