import { Route, Router, Routes } from 'react-router-dom';
import './App.css';
import Dashboard from './components/Dashboard';
import Login from './components/Login';
import Client from './components/Client';
import ClientDetail from './components/ClientDetail';
import InvoiceBill from './components/InvoiceBill';

function App() {
  return (
    <Routes>
      <Route path='/' element={<Login />} />
      <Route path='/dashboard' element={<Dashboard />} />
      <Route path='/client' element={<Client />} />
      <Route path='/client-detail' element={<ClientDetail/>} />
      <Route path='/invoice-bill' element={<InvoiceBill/>} />
    </Routes>

  );
}

export default App;
