import React, { useEffect, useState } from 'react';
import { MDBTable, MDBTableHead, MDBTableBody } from 'mdb-react-ui-kit';
import Sidebar from './Sidebar';
import Header from './Header';
import axios from 'axios';
import AddClientModal from './AddClientModal';
import baseUrl from './Constant';
import { useNavigate, } from 'react-router-dom';



function Client() {
    const [sidebarOpen, setSidebarOpen] = useState(true);
    const [clientList, setClientList] = useState([]);
    const [basicModal, setBasicModal] = useState(false);

    const navigate = useNavigate()


    const toggleOpen = () => setBasicModal(!basicModal);


    const handleToggle = () => {
        setSidebarOpen(!sidebarOpen);
    };

    const getClientList = async () => {
        const token = JSON.parse(localStorage.getItem('token'));
        console.log(token)
        try {
            const res = await axios.get(`${baseUrl}/adminpannel/userlist/`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            console.log(res.data);
            setClientList(res.data.results); // Set the client list state with the fetched data
        } catch (error) {
            console.error('Error fetching client list:', error);
        }
    };

    const handleClientAdded = (newClient) => {
        setClientList(prevList => [...prevList, newClient]);
    };


    useEffect(() => {
        getClientList();
    }, []);

    return (
        <div>
            <Header handleToggle={handleToggle} /> {/* Pass handleToggle function as prop */}
            <div className='d-flex'>
                <Sidebar isOpen={sidebarOpen} /> {/* Pass isOpen state as prop */}
                <div className='container py-3'>
                    <button className=' btn btn-success mb-2' onClick={toggleOpen}>Add Client</button>
                    <div className='card p-3'>
                        <MDBTable hover>
                            <MDBTableHead>
                                <tr>
                                    <th scope='col'>Sr.No</th>
                                    <th scope='col'>Name</th>
                                    <th scope='col'>Email Id</th>
                                    <th scope='col'>Phone No.</th>
                                    <th scope='col'>Action</th>

                                </tr>
                            </MDBTableHead>
                            <MDBTableBody>
                                {clientList.map((client, index) => (
                                    <tr key={client.id}>
                                        <th scope='row'>{index + 1}</th>
                                        <td>{client.full_name}</td>
                                        <td>{client.email}</td>
                                        <td>{client.phone_number}</td>
                                        <td>
                                            <button  className='btn btn-info'
                                                onClick={() => navigate('/client-detail', { state: { clientId: client.id } })}
                                            >
                                                Views
                                            </button>
                                        </td>
                                    </tr>
                                ))}
                            </MDBTableBody>
                        </MDBTable>
                    </div>
                </div>
            </div>
            <AddClientModal basicModal={basicModal} toggleOpen={toggleOpen} onClientAdded={handleClientAdded} />

        </div>
    );
}

export default Client;
