import React from 'react';
import { MDBTable, MDBTableHead, MDBTableBody } from 'mdb-react-ui-kit';
import { useNavigate } from 'react-router-dom';


export default function InvoiceDetail({ invoiceData }) {
    const navigate=useNavigate()


    const handleViewBill=(item)=>{
        console.log(item.uid)
        navigate('/invoice-bill',  { state: { itemUid: item.uid } })
    }
  
    return (
        <div className='card '>
            <MDBTable hover>
                <MDBTableHead className='p-4'>
                    <tr>
                        <th scope='col' className='p-3'>Sr.No</th>
                        <th scope='col' className='p-3'>Invoice No.</th>
                        <th scope='col' className='p-3'>Date</th>
                        <th scope='col' className='p-3'>Amount</th>
                        <th scope='col' className='p-3'>Payment Status</th>
                        <th scope='col' className='p-3'>Action</th>
                    </tr>
                </MDBTableHead>
                <MDBTableBody>
                    {
                        invoiceData.map((item, index) => {
                            return (
                                <tr key={index}>
                                    <th className='p-3' scope='row'>{index + 1}</th>
                                    <td className='p-3'>{item.invoice_no}</td>
                                    <td className='p-3'>{item.ordered_date}</td>
                                    <td className='p-3'>{item.amount}</td>
                                    <td className='p-3'>
                                        {
                                            item.is_paid ? "paid" : "Unpaid"
                                        }
                                    </td>
                                    <td className='p-3'>
                                        <button className='btn btn-info' onClick={()=>handleViewBill(item)}>View</button>
                                    </td>
                                </tr>
                            )
                        })
                    }
                </MDBTableBody>
            </MDBTable>
        </div>

    );
}