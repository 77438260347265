import React from 'react';
import { MdDashboard } from "react-icons/md";
import { IoMdHome } from "react-icons/io";
import { FaShippingFast } from "react-icons/fa";
import { IoMdContact } from "react-icons/io";
import { GiConfirmed } from "react-icons/gi";
import { MdOutlinePendingActions } from "react-icons/md";
import { FaUsers } from "react-icons/fa";
import { Link } from 'react-router-dom';
import '../Css/Sidebar.css'

function Sidebar({ isOpen }) {

    const sidebarStyle = {
        width: isOpen ? '220px' : '80px',
        transition: 'width 0.3s ease',
        display: isOpen ? 'block' : 'flex',
        justifyContent: isOpen ? 'flex-start' : 'center',
        height: '100vh', 

};

return (
    <>
        <div className="sidebar bg-white" style={sidebarStyle}>
            <div>
                <div className='p-2 '>
                    <div className='fs-5 text fw-bolder font-monospace'>Main</div>
                </div>
                <div className='d-flex align-items-center gap-2 p-2 px-3 sidebarlist '>
                    <MdDashboard size={20} color='#9068be' />
                    {isOpen && <div className='fs-6 text fw-semi-bold  font-Roboto '>Dashboard</div>}
                </div>

                {isOpen && (
                    <div className='p-2'>
                        <div className='fs-5 text fw-bolder font-monospace'>Manage order</div>
                    </div>
                )}
                <div>
                    <div className='d-flex align-items-center gap-2 p-2 px-3  sidebarlist'>
                        <IoMdHome size={20} color='#9068be' />
                        {isOpen && <div className='fs-6 text fw-semi-bold ms-2 font- Roboto'>Dashboard</div>}
                    </div>
                    <div className='d-flex align-items-center  gap-2 p-2 px-3  sidebarlist'>
                        <FaShippingFast size={20} color='#9068be' />
                        {isOpen && <div className='fs-6 text fw-semi-bold ms-2 font-Roboto'>Ship</div>}
                    </div>
                    <div className='d-flex align-items-center  gap-2 p-2 px-3  sidebarlist'>
                        <IoMdContact size={20} color='#9068be' />
                        {isOpen && <div className='fs-6 text fw-semi-bold ms-2 font-Roboto'>Contact</div>}
                    </div>
                    <div className='d-flex align-items-center gap-2 p-2 px-3  sidebarlist'>
                        <GiConfirmed size={20} color='#9068be' />
                        {isOpen && <div className='fs-6 text fw-semi-bold ms-2 font-Roboto'>Confirmed</div>}
                    </div>
                    <div className='d-flex align-items-center gap-2 p-2 px-3  sidebarlist'>
                        <MdOutlinePendingActions size={20} color='#9068be' />
                        {isOpen && <div className='fs-6 text fw-semi-bold ms-2 font-Roboto'>Pending</div>}
                    </div>
                    <Link to='/client'>
                        <div className='d-flex align-items-center gap-2 p-2 px-3  sidebarlist'>
                            <FaUsers size={20} color='#9068be' />
                            {isOpen &&
                                <div className='fs-6 text fw-semi-bold ms-2 font-Roboto text-color-red'>All Client</div>
                            }
                        </div>
                    </Link>
                </div>
            </div>

        </div >
    </>
);
}

export default Sidebar;
