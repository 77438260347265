import React, { useState, useEffect } from 'react';
import '../Css/Login.css'
import axios from 'axios'
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import baseUrl from './Constant';


function Login() {
    const [emailId, setEmailId] = useState('')
    const [passwords, setPasswords] = useState('')
    const navigate = useNavigate();


    const handleLogin = async (e) => {
        e.preventDefault();
        const loadingToast = toast.loading("Logging in...");
        try {
            const res = await axios.post(`${baseUrl}/adminpannel/adminlogin/`, {
                email: emailId,
                password: passwords
            });
            if (res.data.status === 200) {
                const token = res.data.access;
                localStorage.setItem('token', JSON.stringify(token));
                toast.update(loadingToast, {
                    render: 'Login successful!',
                    type: 'success',
                    isLoading: false,
                    autoClose: 3000,
                });
                navigate('/dashboard');
            } else {
                const errorMessage = res.data.message
                toast.update(loadingToast, {
                    render: errorMessage,
                    type: 'error',
                    isLoading: false,
                    autoClose: 3000,
                });
            }
        } catch (error) {
            console.log(error);
            toast.update(loadingToast, {
                render: 'Login failed. Please try again.',
                type: 'error',
                isLoading: false,
                autoClose: 3000,
            });
        }
    };

    useEffect(() => {
        const token = localStorage.getItem('token');
        if (token) {
            navigate('/dashboard');
        } else {
            navigate('/');
        }
    }, [ navigate]);

    return (
        <div className='login-con'>
            <div className="wrapper bg-white">
                <div className="h2 text-center">Crm Login</div>
                <div className="h4 text-muted text-center pt-2">Enter your login details</div>
                <form className="pt-3" onSubmit={handleLogin}>
                    <div className="form-group py-2">
                        <div className="input-field">
                            <span className="far fa-user p-2"></span>
                            <input type="text" placeholder="Username or Email Address" required value={emailId} onChange={(e) => { setEmailId(e.target.value) }} />
                        </div>
                    </div>
                    <div className="form-group py-1 pb-2">
                        <div className="input-field">
                            <span className="fas fa-lock p-2"></span>
                            <input type="text" placeholder="Enter your Password" required
                                value={passwords} onChange={(e) => { setPasswords(e.target.value) }} />
                            <button className="btn bg-white text-muted">
                                <span className="far fa-eye-slash"></span>
                            </button>
                        </div>
                    </div>
                    <div className="d-flex align-items-start">
                        <div className="remember">
                            <label className="option text-muted"> Remember me
                                <input type="radio" name="radio" />
                                <span className="checkmark"></span>
                            </label>
                        </div>
                        <div className="ms-auto">
                            <a href="#" id="forgot">Forgot Password?</a>
                        </div>
                    </div>
                    <button type="submit" className="btn btn-block text-center my-3">Log in</button>
                    {/* <div className="text-center pt-3 text-muted">Not a member? <a href="#">Sign up</a></div> */}
                </form>
            </div>
        </div>
    );
}

export default Login;
